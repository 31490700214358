<template>
  <div>
    <vs-card>
      <h1>Meus dados</h1>
      <p>
        Nome: <strong>{{ loggedUser.name }}</strong>
      </p>
      <p>
        AmbevId: <strong>{{ loggedUser.ambevId }}</strong>
      </p>

      <p>
        Perfil: <strong>{{ loggedUser.profile.name }}</strong>
      </p>
      <p>
        Unidade: <strong>{{ loggedUser.unit.name }}</strong>
      </p>
      <fieldset>
        <legend>E-mail</legend>
        <p>
          <vs-input
            v-validate="'required|email'"
            name="newPass"
            v-model="loggedUser.email"
            data-vv-as="E-mail"
            type="email"
          ></vs-input>
          <br /><vs-button class="mr-6" @click="updateEmail()"
            >Salvar e-mail</vs-button
          >
        </p>
      </fieldset>
      <fieldset>
        <legend>Alterar senha</legend>
        <p>Nova senha:</p>
        <vs-input
          v-validate="'required'"
          name="newPass"
          v-model="newPass"
          data-vv-as="Nova Senha"
          type="password"
        ></vs-input>
        <p>Confirmar Nova senha:</p>
        <vs-input
          v-validate="'required'"
          name="newPassConfirm"
          v-model="newPassConfirm"
          data-vv-as="Nova Senha"
          type="password"
        ></vs-input>
        <br />
        <vs-button class="mr-6" @click="updatePassword()">Alterar</vs-button>
      </fieldset>
    </vs-card>
  </div>
</template>

<script>
import { notifyError, notifySuccess } from "../utils/helpers";
import siriusAPI from "../services";
export default {
  data() {
    return {
      loggedUser: null,
      newPassConfirm: null,
      newPass: null,
    };
  },

  created() {
    this.loggedUser = JSON.parse(localStorage.getItem("userInfo"));
  },
  mounted() {
    if (this.loggedUser.shouldChangePassword == true)
      this.$vs.dialog({
        color: this.colorAlert,
        title: `Alterar senha`,
        text: "Você precisa alterar a sua senha!",
        acceptText: "Ok",
        accept: null,
      });
  },
  methods: {
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    async updatePassword() {
      if (this.newPassConfirm == this.newPass && this.newPass) {
        const data = {
          password: this.newPass,
        };

        this.$vs.loading();
        await new siriusAPI()
          .updateUser(data, this.loggedUser.uuid)
          .then(() => {
            this.$vs.loading.close();
            notifySuccess(this, "Senha alterada com sucesso!");
            this.newPass = null;
            this.newPassConfirm = null;
            this.loggedUser.shouldChangePassword = false;
            let userInfo = JSON.stringify(this.loggedUser);
            localStorage.setItem("userInfo", userInfo);
          })
          .catch((error) => {
            this.$vs.loading.close();
            notifyError(
              this,
              "Erro ao tentar alterar a senha: " + error.response.data.message
            );
          });
      } else {
        notifyError(this, "Senhas não conferem, favor corrigir");
      }
    },
    async updateEmail() {
      if (!this.validateEmail(this.loggedUser.email)) {
        notifyError(this, "Informe um e-mail válido!");
        return;
      }
      const data = {
        email: this.loggedUser.email,
      };

      this.$vs.loading();
      await new siriusAPI()
        .updateUser(data, this.loggedUser.uuid)
        .then(() => {
          this.$vs.loading.close();
          notifySuccess(this, "E-mail alterado com sucesso!");
          let userInfo = JSON.stringify(this.loggedUser);
          localStorage.setItem("userInfo", userInfo);
        })
        .catch((error) => {
          this.$vs.loading.close();
          if (error.response.data.statusCode == "401") {
            localStorage.clear();
            sessionStorage.removeItem("sirius-key");
            this.$router.push("/").catch(() => {});
            this.$acl.change(["public"]);
          } else {
            notifyError(
              this,
              "Erro ao tentar alterar o e-mail: " + error.response.data.message
            );
          }
        });
    },
  },
};
</script>
<style scoped>
fieldset {
  border: 0.8px solid #b6b2b2;
  padding: 0.5em;
}
fieldset legend {
  font-weight: bold;
}
</style>
